import { ModelBase } from 'store/model-base';

export class CmsCustomizer extends ModelBase {
    public static storeName: string = 'cmsCustomizer';

    public customizerPlan: CmsCustomizerPlan;
    public customizerWatch: CmsCustomizerWatch;
    public customizerTablet: CmsCustomizerTablet;
    public title: {
        grandfather: string;
        nonGrandfather: string;
        byod: string;
        line: string;
    };
    public your: string;
    public ndelOptionTitle: string;
    public newLineOption: string;
    public ndelLineOption: string;
    public dataOptionTitle: string;
    public byodBanner: CmsMediaOptionsImage;
    public byodMobileBanners: {
        backgroundImage: CmsMediaImage;
        leftImage: CmsMediaImage;
        rightImage: CmsMediaImage;
    };
    public byodPricing: {
        subheader: string;
        description: string;
        image: CmsMediaImage;
    };
    public editFlowCTAs: CmsEditFlowCTAs;
    public planDescription: CmsPlanDescription;
    public estimatedMonthlyReceipt: CmsEstimatedMonthlyReceipt;
    public ndelLine: {
        ndelLineHeader: string;
        xmcTitle: string;
        dppPaid: string;
        dppRemaining: string;
        dppHalfPaidHasXmc: string;
        dppHalfPaidNoXmc: string;
        dppNotHalfPaidHasXmc: string;
        dppNotHalfPaidNoXmc: string;
        ndelLineInfo: string;
        ndelClaimInfo: string;
        ndelLineHasXmcDisclaimer1: string;
        ndelLineHasXmcDisclaimer2: string;
        ndelLineHasXmcDisclaimer3: string;
        findStoreCtaText: string;
        imagePath: string;
        imageAlt: string;
        claimCtaText: string;
        xmcAddedText: string;
        xmcAlreadyAddedText: string;
    };
    public tradeInModule: {
        images: CmsResponsiveImagesGroup;
        header: string;
        svgTradeInIcon: string;
        tipSubheader: string;
        itiSubheader: string;
        howTradeInWork: string;
    };
    public byTheGigIcon: CmsMediaImage;
    public unlimitedIcon: CmsMediaImage;
    public phoneNumberTitle: string;
    public watchUserTitle: string;
    public byodPhoneUserTitle: string;
    public phoneNumberAriaLabel: string;
    public portNumberLabel: string;
    public keepNumberLabel: string;
    public phoneInputLabel: string;
    public phoneHeaderClass: string;
    public phoneInputTemplate: string;
    public carrierLabel: string;
    public carrierHeaderClass: string;
    public carrierPlaceholder: string;
    public carriers: DropdownItem[];
    public phoneUserTitle: string;
    public nicknameInputErrors: XmInputError[];
    public phoneInputErrors: XmInputError[];
    public carrierInputErrors: XmInputError[];
    public portInputErrors: XmInputError[];
    public protectionInputErrors: XmInputError[];
    public ndelInputErrors: XmInputError[];
    public headingXmc: string;
    public xmcLabel: string;
    public noXmcLabel: string;
    public noXmcPrice: string;
    public plusData: string;
    public estimatedMonthly: string;
    public prospectEstimatedMonthly: string;
    public details: string;
    public prospectDetails: string;
    public creditCheckText: string;
    public buttonText: string;
    public tradeInButtonText: string;
    public apiError: string;
    public duplicateImeiError: string;
    public duplicateIccidError: string;
    public pifNdelError: string;
    public portinSameNumberApiError: string;
    public lineTypeTooltip: DescriptiveModalData;
    public billImageOptions: CmsMediaImage;
    public xmcDisclaimers: CmsXmcDisclaimer[];
    public acpDisclaimers: CmsAcpDisclaimer;
    public xmcHigherTierNote: string;
    public fiveGBadgeAvailable: CmsFiveGBadge;
    public includedWithPlans: CmsIncludedWithPlans;
    public fiveGExcluded: string;
    public variantErrorText: string;
    public informationButton: string;
    public unlimitedMultiLinePricing: CmsUnlimitedMultiLinePricing;
    public amexIcon: CmsMediaImage;
    public visaIcon: CmsMediaImage;
    public mcIcon: CmsMediaImage;
    public discoverIcon: CmsMediaImage;
    public namePlaceHolder: string;
    public xmcOptionLabel: string;
    public imeiInUseError: string;

    public customizerTitle: {
        grandfather: string;
        nonGrandfather: string;
        byod: string;
        line: string;
        phoneLine: string;
        tabletLine: string;
    };
    
    public broadbandLabels: {
        header: string;
        whyChooseHeader: string;
        faqHeader: string;
    };
    public byTheGig: string;
    public planOptionDescription: CmsPlanOptionDescription;
}
