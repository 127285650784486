import { HttpResponse } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { MaskFields } from 'core/constants';

@Pipe({
    name: 'mask'
})
export class MaskPipe implements PipeTransform {

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public transform(value: string, replaceChar?: string): any {
        // eslint-disable-next-line
        if (value === undefined || value === null) {

            return value;
        }
        // Replace with the specified character
        if (replaceChar) {
            return replaceChar.repeat(value.length);
        }
        
        // Replace value with asterisks
        return '*'.repeat(value.length);
    }


    /* eslint-disable @typescript-eslint/no-explicit-any */
    /* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
    public maskJSONValue(event: any, shouldMask: boolean): any {
    
        let responseBody;
        let data;
        if (event instanceof HttpResponse) {
            data = JSON.parse(JSON.stringify(event.body));                
            this.verifyAndMaskValue(data, shouldMask);
            responseBody = data;
        } else if (event.type !== 0) {
            data = JSON.parse(event);
            this.verifyAndMaskValue(data, shouldMask);
            responseBody = data;
        }      
    
        return responseBody;
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    /* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
    public verifyAndMaskValue(data: any, shouldMask: boolean = false): any {
        for (const key in data) {
            // eslint-disable-next-line
            if (typeof(data[key]) === 'object' && (data[key] !== null)) {
                this.maskvalue(data[key], shouldMask);
            } else {
                if (MaskFields.includes(key.toUpperCase())) {
                    const maskedValue = this.transform(data[key]);
                    data[key] = maskedValue;
                } else if (key.toUpperCase() === 'NAME' && shouldMask ) {
                    const maskedValue = this.transform(data[key]);
                    data[key] = maskedValue;    
                }
            }
        }
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    /* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
    public maskvalue(objectToMask: any, shouldMask: boolean): any {
        for (const key in objectToMask) {
            if (typeof (objectToMask[key]) === 'object') {
                this.maskvalue(objectToMask[key], shouldMask);
            } else {
                if (MaskFields.includes(key.toUpperCase())) {
                    const maskedValue = this.transform(objectToMask[key]);
                    objectToMask[key] = maskedValue;
                } else if (key.toUpperCase() === 'NAME' && shouldMask ) {
                    const maskedValue = this.transform(objectToMask[key]);
                    objectToMask[key] = maskedValue;
    
                }
            }
        }
        
        return objectToMask;
    }    
}