
export enum ParamType {
    encoded = 'encodedParam', // only one that is custom to XM
    path = 'path',
    query = 'query',
    any = 'any'
}

export enum TrialFeatures {
    ESIM = 'ESIM',
    INCREASE_LINES = 'INCREASE_LINES',
    ANDROID_WATCH = 'ANDROID_WATCH',
    IPAD_BYO = 'IPAD_BYO',
    SERVICE_PROMO = 'SERVICE_PROMO',
    APPLE_CARE = 'APPLE_CARE',
    XMC_PLUS = 'XMCPLUS',
    DDA = 'DDA'
}

export enum PostMessageCodes {
    OPTIONS_OVERRIDE = 'OPTIONS_OVERRIDE',
    READY_TO_RECEIVE_OPTIONS_OVERRIDE = 'READY_TO_RECEIVE_OPTIONS_OVERRIDE',
    READY_TO_RECEIVE_SESSION_DATA = 'READY_TO_RECEIVE_SESSION_DATA',
    SESSION_DATA = 'SESSION_DATA',
    READY_TO_RECEIVE_EDIT_DATA = 'READY_TO_RECEIVE_EDIT_DATA',
    TPS_DATA = 'TPS_DATA',
    READY_TO_RECEIVE_TPS = 'READY_TO_RECEIVE_TPS',
    READY_TO_RECEIVE_PROMOCODE = 'READY_TO_RECEIVE_PROMOCODE',
    PROMO_CODE = 'PROMO_CODE',
    GET_PROMO_ELIGIBILITY = 'GET_PROMO_ELIGIBILITY',
    ADD_TO_CART_ERROR = 'ADD_TO_CART_ERROR',
    SCANNED_ICCID = 'SCANNED_ICCID',
    SCANNED_DATA = 'SCANNED_DATA',
    READY_TO_RECEIVE_ORDER_LIMIT_DATA = 'READY_TO_RECEIVE_ORDER_LIMIT_DATA',
    ORDER_LIMIT_DATA = 'ORDER_LIMIT_DATA',
    READY_TO_RECEIVE_BYO_SERVICE_PROMO_DATA = 'READY_TO_RECEIVE_BYO_SERVICE_PROMO_DATA',
    BYO_SERVICE_PROMO = 'BYO_SERVICE_PROMO'
}


export enum ClearTradeInOfferSource {
    REMOVE = 'remove',
    ADDTOCART = 'addtocart'
}

export enum TradeInProgram {
    ITI = 'ITI',
    TIP = 'TIP',
    EARLY_UPGRADE = 'EARLY_UPGRADE'
}

export enum FormType {
    blur = 'blur',
    submit = 'submit',
    default = 'change'
}

export enum TradeInsStep {
    LEARN_MORE = 'trade-in.learn-more',
    CHECK_CONDITION = 'trade-in.check-condition',
    IMEI = 'trade-in.imei',
    CONFIRM = 'trade-in.confirm',
    RESULT = 'trade-in.result',
    INELIGIBLE = 'trade-in.ineligible',
    ACCEPT_TRADE_IN = 'accept-trade-in'
}

export enum ChargeCategory {
    READY_FOR_PAYMENT = 'READY_FOR_PAYMENT'
}


export enum ApiChannel {
    CMS = 'CMS',
    BOOTSTRAP_API = 'BOOTSTRAP_API',
    GATEWAY = 'GATEWAY',
    GATEWAY_FOR_GUID = 'GATEWAY_FOR_GUID',
    PROXY = 'PROXY',
    AKAMAI_PROXY = 'AKAMAI_PROXY',
    TIP_PROXY = 'TIP_PROXY',
    INBENTA = 'INBENTA',
    MOCKABLE = 'MOCKABLE',
    PCAT = 'PCAT',
    XAPI = 'XAPI',
    SESSION_API='SESSION_API',
    KIBANA_CODEBIG_API='KIBANA_CODEBIG_API',
    SITECORE_URL = 'SITECORE_URL',
    ACCOUNT_REGISTRATION = 'ACCOUNT_REGISTRATION',
    BROADBAND_LABELS = 'BROADBAND_LABELS',
    AKAMAI_URL = 'AKAMAI_URL'
}

export enum BreakpointWidth {
    MOBILE = 375,
    TABLET = 768,
    SMALL_DESKTOP = 960,
    LARGE_DESKTOP = 1440
}

export enum ImageFit {
    HEIGHT = 'height',
    WIDTH = 'width',
    CONTAIN = 'contain'
}

export enum StorageToken {
    CIMA = 'xm-access-token',
    MBC = 'xm-mbc-access',
    SAVED_STATE = 'XM_SAVED_STATE',
    SAVED_FAILED_REQUESTS = 'xm-failed-requests',
    CBM_SAVED_LAST_STATE = 'CBM_SAVED_LAST_STATE',
    CUSTOMIZING_ITEM = 'CUSTOMIZING_ITEM',
    IS_EDIT_CART_FLOW = 'IS_EDIT_CART_FLOW',
    BYOD_PORT_COUNT = 'BYOD_PORT_COUNT',
    TPS = 'TPS',
    AB_TESTS = 'AB_TESTS',
    TARGETED_PROMOTIONS = 'TARGETED_PROMOTIONS',
    ADOBE = 'XM-ANALYTICS',
    SHARED_CARTS = 'xfinityCart',
    SIMLED = 'SIMLED',
    VISITOR_SESSION_ID ='VISITOR_SESSION_ID',
    CUSTOMER_TYPE = 'CUSTOMER_TYPE',
    LIABILITY_TYPE = 'LIABILITY_TYPE',
    USER_DETAIL = 'USER_DETAIL',
    CBM_TEST_USER ='CBM_TEST_USER',
    TOAST_HIDDEN = 'TOAST_HIDDEN',
    CBM_TRADE_IN_DETAILS = 'CBM_TRADE_IN_DETAILS',
    CBM_TRADE_IN_FLOW_STATE = 'CBM_TRADE_IN_FLOW_STATE',
    CBM_IS_EDIT_TRADE_IN_FLOW = 'CBM_IS_EDIT_TRADE_IN_FLOW',
    CBM_CIMA_CHECK = 'CBM_CIMA_CHECK',
    DEVICE_FINGERPRINT_ID = 'DEVICE_FINGERPRINT_ID',
    CUSTOMER_LOCATION_NETWORK = 'CUSTOMER_LOCATION_NETWORK'
}

export enum TransitionHooks {
    START = 'transitionStart',
    SUCCESS = 'transitionSuccess',
    PARAMS = 'paramsChanged'
}

export enum ActiveNewLines {
    MAX_UNL_LIMIT_REACHED = 'maxUnlLimitReached',
    NO_ACTIVE_LINES = 'noActiveLines',
    IS_DEVICE_FORCETRADEIN = 'isDeviceForceTradeIn'
}

export const PASSIVE_OAUTH_BLACKLIST: RegExp[] = [
    /support.lite/,
    /^watch\..+/
];

export const BUTTON_LOADER_CLASS: string = 'is-loading'; // only used for JS, need to also change scss files
export const XFINITY_CARRIER_NAME: string = 'XfinityMobile';
export const DEFAULT_CUSTOMER_ELIGIBILITY_CODE: string = 'EC0';
export const CBM_NEW_CUSTOMER: string = 'EC400';
export const XM_PAST_DUE: string = 'EC2100';
export const XM_NON_PAY_DISCONNECT: string = 'EC2200';
export const CORE_BILL_DUE: string = 'EC600';
export const BREADCRUMB_TOKEN: string = 'breadcrumb';
export const CONFIG_TOKEN: string = 'CONFIG_TOKEN';
export const REWRITE_TOKEN: string = 'REWRITE_TOKEN';
export const MILLISECOND_MULTIPLIER: number = 1000;
export const CENTS: number = 2;
export const FREE_MB: number = 100;
export const ACCESSORY_ADD_CART_LIMIT: number = 8;
export const APPLE_BRAND_NAME: string = 'Apple';
export const SHIP_TO_HOME: string = 'SHIP_TO_HOME';
export const NO_SHIP: string = 'NO_SHIP';
export const CHECKOUT_STATE_PAYMENT: string = 'payment';
export const CHECKOUT_STATE_SHIPPING: string = 'shipping';
export const CHECKOUT_STATE_CONTACT_INFO: string = 'contact-info';
export const DEFAULT_DERAIL_STATE: string = 'https://business.comcast.com/learn/forms/CBM-derail';
export const MINIMUM_TOAST_PRODUCTS: number = 3;

export const SCREEN_READER_TEXT_SHOP_DROPDOWN: string = 'Results updated';
export const SCREEN_READER_TEXT_LOADING: string = 'Loading';

export const CAROUSEL_DEFAULT_AUTOPLAY_INTERVAL: number = 3000;
export const CAROUSEL_TEST_AUTOPLAY_DELAY: number = 6000;

export enum IdentityCheck {
    FAILED = 'FAILED',
    CLEARED = 'CLEARED'
}

export enum PlanSku {
    SMART_WATCH_DATA = '8888164',
    BY_THE_GIG = '8420046',
    UNLIMITED = '8410397',
    FINANCED = 'RECURRING',
    PAY_IN_FULL = 'ONETIME',
    NO_XMPP = 'NO_XMPP',
    NO_XMC = 'NO_XMC',
    NO_ACP = 'NO_ACP',
    TRADE_IN_OPTIONS = 'TRADE_IN_OPTIONS'
}

export enum PlanSubGroupType {
    BASIC = 'BASIC',
    INTRO = 'INTRO',
    PLUS = 'PLUS',
    PREMIUM = 'PREMIUM'
}

export enum BtgPlanWhyChoose {
    '1 GB' = 'oneGB',
    '3 GB' = 'threeGB',
    '10 GB' = 'tenGB',
    '20 GB' = 'twentyGB',
    '50 GB' = 'fiftyGB',
    '100 GB' = 'hundredGB'
}

export enum UnlPlanWhyChoose {
    'Unlimited Intro' = 'unlimitedIntro',
    'Unlimited Plus' = 'unlimitedPlus',
    'Unlimited Premium' = 'unlimitedPremium',
    'Tablet Unlimited Intro' = 'tabletUnlimitedIntro',
    'Tablet Unlimited Premium' = 'tabletUnlimitedPremium'
}

export enum FinanceOptions {
    BUSINESS = 'BL',
    PERSONAL = 'PL'
}


export enum TradeInDeviceStatus {
    COLLECTED_IN_STORE = 'COLLECTED_IN_STORE',
    TO_BE_SHIPPED = 'TO_BE_SHIPPED'
}

export enum AdditionalChargeType {
    EWSTF = 'EWSTF',
    DAF = 'DAF',
    DEVICE_SUBSIDY = 'DEVICE_SUBSIDY',
    ACTIVATION_FEE = 'ACTIVATION_FEE'

}

export enum PromotionType {
    PERCENTAGE = 'PERCENTAGE',
    DOLLAR_AMOUNT = 'DOLLAR_AMOUNT',
    GIFT_CARD = 'GIFT_CARD',
    SUBSIDY = 'SUBSIDY',
    EXTENDED_PROMO = 'EXTENDED_PROMO',
    EXTENDED_PROMO_DOLLAR = 'EXTENDED_PROMO_DOLLAR',
    EXTENDED_PROMO_PCT = 'EXTENDED_PROMO_PCT',
    EXTENDED_PROMO_UPGRADE = 'EXTENDED_PROMO_UPGRADE',
    EXTENDED_PROMO_DOLLAR_DDA = 'EXTENDED_PROMO_DOLLAR_DDA',
    TRADEIN_PROMO = 'TRADEIN_PROMO',
    BYO_SERVICE_PROMO = 'BYOD_LL_SERP',
    SERVICE_PROMO_LINE = 'SERVICE_PROMO_LINE',
    SERVICE_PROMO_ACCOUNT = 'SERVICE_PROMO_ACCOUNT',
    BYO_SERVICE_PROMO_LINE = 'BYO_SERVICE_PROMO_LINE',
    XMC_PROMOTION_TYPE = 'INSURANCE_PROMO_LINE_DISCOUNT'
}

export enum MouseEventType {
    MOUSE_ENTER = 'mouseenter',
    MOUSE_LEAVE = 'mouseleave'
}

export enum FocusEventType {
    FOCUS_IN = 'focusin',
    FOCUS_OUT = 'focusout'
}

export enum CustomerType {
    NEW_NEW_CUSTOMER = 'BUSINESS_NEW',
    EXISTING_CUSTOMER = 'BUSINESS'
}

export enum PaymentType {
    MONTHLY = 'MONTHLY',
    ONE_TIME = 'ONE_TIME'
}

export enum TaxType {
    TAX = 'TAX',
    FEES = 'FEES'
}

export enum Height {
    NAV_BAR = 56
}

export enum PlanType {
    RatePlan = 'RatePlan',
    ProtectionPlan = 'InsurancePlan',
    PaymentPlan = 'PaymentPlan'
}

export enum ImageType {
    HERO = 'HERO',
    PRIMARY = 'PRIMARY',
    ALTERNATE = 'ALTERNATE'
}

export enum OS {
    ANDROID = 'ANDROID',
    IOS = 'IOS',
    WATCHOS = 'WATCHOS'
}

export enum DeviceBrand {
    APPLE = 'apple',
    SAMSUNG = 'samsung',
    LG = 'lg',
    GOOGLE = 'google',
    OTHER = 'other'
}

export enum ByodCompatibilityStatus {
    INCOMPATIBLE = 'INCOMPATIBLE',
    COMPATIBLE = 'COMPATIBLE',
    COMPATIBLE_USER_ACTION = 'COMPATIBLE_USER_ACTION'
}

export enum ByodImeiCapability {
    ESIM = 'ESIM',
    PSIM = 'PSIM',
    PSIM_ESIM = 'PSIM_ESIM'
}

export enum ByodEsimCapatibilityStatus {
    CONFIRMED = 'CONFIRMED',
    NOT_CONFIRMED = 'NOT_CONFIRMED'
}

export enum Imei {
    IMEI = 'IMEI',
    IMEI1 = 'IMEI1',   
    IMEI2 = 'IMEI2'
}

export enum CreditCheckStatus {
    AVAILABLE = 'AVAILABLE',
    UNAVAILABLE = 'UNAVAILABLE',
    PENDING = 'PENDING'
}

export enum CreditCheckSource {
    ILC = 'ILC',
    ECC = 'ECC'
}

export enum ShopCategory {
    DEVICE = 'DEVICE',
    ACCESSORY = 'ACCESSORIES',
    TABLET = 'TABLET',
    WATCH = 'SMART_WATCH'
}

export enum ItemType {
    DEVICE = 'DEVICE',
    ACCESSORY = 'ACCESSORY',
    NDEL = 'DEVICE_EXCHANGE',
    WATCH = 'SMART_WATCH',
    TABLET = 'TABLET'
}

export enum RequestProgressStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}

export enum CreditCardType {
    MASTER_CARD = 'MASTERCARD',
    VISA = 'VISA',
    AMEX = 'AMEX',
    AMERICANEXPRESS = 'AMERICANEXPRESS',
    DISCOVER = 'DISCOVER'
}

export enum NumericCreditCardType {
    MASTER_CARD = '002',
    VISA = '001',
    AMEX = '003',
    DISCOVER = '004'
}

export enum NavDisplayName {
    CHAT = 'XFINITY ASSISTANT',
    CART = 'CART',
    SEARCH = 'SEARCH',
    SEPARATOR = 'SEPARATOR'
}

export const CHECKOUT_SHIP_BREADCRUMB_STATES: BreadcrumbState[] = [{
    name: 'checkout.shipping',
    displayName: 'Shipping'
}, {
    name: 'checkout.payment',
    displayName: 'Payment'
}, {
    name: 'checkout.review',
    displayName: 'Review'
}];
 
export const CHECKOUT_NO_SHIP_BREADCRUMB_STATES: BreadcrumbState[] = [{
    name: 'checkout.contact-info',
    displayName: 'Contact Info'
}, {
    name: 'checkout.payment',
    displayName: 'Payment'
}, {
    name: 'checkout.review',
    displayName: 'Review'
}];

export enum Availability {
    AVAILABLE = 'AVAILABLE',
    PREORDER = 'PREORDER',
    BACKORDER = 'BACKORDER',
    OUT_OF_STOCK = 'OUT_OF_STOCK'
}

export enum KeyboardEventKeys {
    ARROW_UP = 'ArrowUp',
    ARROW_DOWN = 'ArrowDown',
    ARROW_LEFT = 'ArrowLeft',
    ARROW_RIGHT = 'ArrowRight',
    ENTER = 'Enter',
    SPACE = ' ',
    ESCAPE = 'Escape',
    HOME = 'Home',
    END = 'End',
    TAB = 'Tab',
    BACKSPACE = 'Backspace',
    DELETE = 'Delete'
}

export enum MBCMessageCodes {
    SESSIONLOCKED = 'MBC-8027',
    RETRY = 'MBC-8028',
    IDA_FAILED = 'MBC-8035',
    BACKEND_FAILURE = 'MBC-8034',
    BUSINESS_NAME_MISMATCH = 'MBC-8041',
    SSN_DIFFERENT_ACCOUNT = 'MBC-8042',
    NAME_MISMATCH = 'MBC-8044',
    INPUT_INCORRECT_OR_MISSING = 'MBC-8046',
    SYSTEM_ERROR = 'MBC_8047',
    INPUT_INCORRECT_OR_MISSING_2 = 'MBC-8045',
    INVALID_TAX_ID = 'MBC-8049',
    TAX_ID_INCOMPLETE_OR_BLANK = 'MBC-8048',
    SSN_MISMATCH = 'MBC-8043',
    BACKEND_SYSTEM_FAILURE = 'MBC-8036',
    DUPLICATE_NICKNAME = 'MBC-7066',
    CANT_PORT = 'MBC-7072',
    NEW_NEW_DEVICE_LIMIT = 'MBC-7288',
    DEVICE_LIMIT = 'MBC-7052',
    DEVICE_LIMIT_WITH_ITEM_IN_CART = 'MBC-7331',
    ORDER_LIMIT = 'MBC-7350',
    CREDIT_FROZEN = 'MBC-7366',
    PREORDER_DEVICE_LIMIT = 'MBC-7117',
    ADD_LIMIT_PREORDER_IN_CART = 'MBC-7119',
    ADD_LIMIT_BACKORDER_IN_CART = 'MBC-7226',
    ADD_LIMIT_NDEL_IN_CART = 'MBC-7278',
    CHECKOUT_LIMIT_PREORDER_IN_CART = 'MBC-7118',
    CHECKOUT_LIMIT_BACKORDER_IN_CART = 'MBC-7227',
    CHECKOUT_LIMIT_NDEL_IN_CART = 'MBC-7280',
    INSUFFICIENT_FUNDS = 'MBC-7312',
    PAYMENT_ERROR = 'MBC-7021 - PAYMENT',
    PAYMENT_INSUFFICIENT_FUNDS = 'MBC-7021 - PAYMENT 1175',    
    PAYMENT_GENERAL_ERROR = 'MBC-7021 - PAYMENT 1239',
    PAYMENT_CARD_EXPIRED = 'MBC-7021 - PAYMENT 1150',
    INSUFFICIENT_FUNDS_IN_ACCOUNT = 'MBC-8053',
    CARD_PROCESSOR_DECLINE__INVALID_CARD_ERROR = 'MBC-8056',
    CARD_PROCESSOR_DECLINE_ERROR = 'MBC-8062',
    CARD_PROCESSOR_DECLINE_ERROR_ACCOUNT_CLOSED = 'MBC-8085',
    INVALID_CARD = 'MBC-7313',
    FORCE_ECC = 'MBC-7305',
    FILE_FROZEN = 'MBC-7366',
    ILC_PARTIAL_SUCCESS = 'MBC-7303',
    ECC_PARTIAL_SUCCESS = 'MBC-7304',
    INVALID_DOB_CREDIT = 'MBC-7349',
    CREDIT_CHECK_HARD_STOP = 'MBC-7307',
    CREDIT_CHECK_DONE = 'MBC-7306',
    CREDIT_CHECK_UNAVAILABLE_CONTACT = 'MBC-7308',
    INVALID_SSN = 'MBC-7009',
    INVALID_DOB = 'MBC-7092',
    CREDIT_CHECK_UNAVAILABLE = 'MBC-8027',
    CONTACT_CREDIT_BUREAU = 'MBC-7347',
    CREDIT_BUREAU_UNAVAILABLE = 'MBC-7348',
    BYOD_DUPLICATE_IMEI = 'MBC-7064',
    SIMLED_BYOD_DUPLICATE_ICCID = 'MBC-7055',
    CAN_BUY_PIF_NDEL = 'MBC-7346',
    REQUIRED_FIELD_MISSING = 'MBC-7374',
    ACCOUNT_PENDING_REVIEW = 'MBC-7373',
    PIF_NDEL = 'MBC-7332',
    PORTIN_SAME_NUMBER = 'MBC-7292',
    NEGOTIABLE_ITEM = 'MBC-7333',
    DOWNPAYMENT_AMOUNT_CHANGE = 'MBC-7334',
    WATCH_BUY_INELIGIBLE = 'MBC-7321',
    ACCESSORY_IN_CART_LIMIT = 'MBC-7328',
    LAST_PHONE_IN_CART = 'MBC-7337',
    ADD_PREORDER_BACKORDER_WATCH = 'MBC-7340',
    ADD_WATCH_PREORDER_IPHONE_IN_CART = 'MBC-7341',
    ADD_DEVICE_PREORDER_WATCH_IN_CART = 'MBC-7342',
    ACTIVE_SIM = 'MBC-7357',
    READY_TO_ACTIVATE_SIM = 'MBC-7356',
    ERROR_LIMIT_REACHED = 'MBC-7353',
    BAD_SIM = 'MBC-7354',
    SIM_NOT_XM_RANGE = 'MBC-7355',
    ADD_DEVICE_WHEN_SIMLED_ALREADY_IN_CART = 'MBC-7353',
    ADD_DEVICE_WHEN_ESIM_IN_CART = 'MBC-7379',
    NO_FULFILLMENT_METHOD = 'MBC-7380',
    ADD_SIMLED_WHEN_DEVICE_ALREADY_IN_CART = 'MBC-7354',
    PLANS_ELIGIBILITY_UNLIMITED_LINE_LIMIT = 'MBC-14001',
    EXCEEDS_MAXIMUM_DOLLAR_LIMIT_ONE = 'MBC-7377',
    EXCEEDS_MAXIMUM_DOLLAR_LIMIT_TWO = 'MBC-7350',
    EXCEEDS_MAXIMUM_OFFERED_DOLLAR_LIMIT = 'MBC-7378',
    EXCEEDS_MAXIMUM_OFFERED_DOLLAR_LIMIT_AFTER_DP = 'MBC-7410',
    ITEM_NO_LONGER_AVAILABLE = 'MBC-7104',
    SESSION_EXPIRY = 'MBC-7032',
    SESSION_EXPIRY_ERROR = 'MBC-7008',
    SESSION_EXPIRY_DOB_MISSING ='MBC-7085',
    DOWN_PAYMENT_MISMATCH = 'MBC-7330',
    TRADE_IN_IMEI_IN_USE = 'MBC-7370'
}

export enum LineEligibilityConditionalCodes {
    HAS_XMC = 'NDEL500',
    PAID_IN_FULL = 'UPEL300',
    PAID_HALF = 'UPEL400'
}

export enum FullfillmentMethods {
    FEDEX_2_DAY = 'FEDEX_2_DAY'
}

export enum Brand {
    IPHONE = 'Apple iPhone',
    GALAXY = 'Samsung Galaxy',
    PIXEL = 'Google Pixel'
}

export enum BUY_CHANNEL {
    ONLINE = 'ONLINE',
    RETAIL = 'RETAIL',
    TELESALES = 'TELESALES',
    RETAIL_INSTORE = 'RETAIL_INSTORE'
}

export enum ProductCategory {
    PHONE = 'PHONE',
    ACCESSORY = 'ACCESSORY',
    TABLET = 'TABLET',
    SMART_WATCH = 'SMART_WATCH'
}

export enum PlanGroupType {
    BY_THE_GIG = 'BY_THE_GIG',
    BY_THE_GIG_SHARED = 'BY_THE_GIG_SHARED',
    UNLIMITED = 'UNLIMITED',
    SMART_WATCH = 'SMART_WATCH',
    ACCESSORY = 'ACCESSORY',
    XMPP = 'XMPP',
    XMC = 'XMC',
    ACP = 'ACP',
    TABLET = 'TABLET'
}

export const MARKETING_STATES: RegExp[] = [
    /^landing.landing$/,
    /^plan\..+/,
    /^shop\.(?!(customize|eligibility)).+/,
    /^policies\..+/,
    /^network\..+/,
    /^byod.compatibility.device$/,
    /^support\..+/
];

export const MARKETING_PARAMS: string[] = [
    'INTCMP',
    'CMP'
];

export const CAMPAIGN_ID: string = 'CAMPAIGN_ID';

export const XMPP_NAME: string = 'Xfinity Mobile Protection Plan';

export const XMC_NAME: string = 'Xfinity Mobile Care';

export const ACP_NAME: string = 'AppleCare+';

export enum PaymentPlanName {
    FINANCED = 'Monthly',
    PAY_IN_FULL = 'Pay In Full'
}

export enum BuyInfoAccountType {
    RESIDENTIAL = 'RESIDENTIAL',
    BUSINESS = 'BUSINESS'
}

export enum AccountType {
    LEVEL_1 = 'LEVEL_1',
    LEVEL_2 = 'LEVEL_2',
    LEVEL_3 = 'LEVEL_3',
    LEVEL_4 = 'LEVEL_4',
    LEVEL_5 = 'LEVEL_5',
    IN_TRANSITION = 'IN_TRANSITION',
    BLOCKED = 'BLOCKED'
}

export enum IS_CBM_CUSTOMER {
    YES = 'Yes',
    NO = 'No'
}

export enum DownPaymentStatus {
    UNKNOWN = 'UNKNOWN',
    REQUIRED = 'REQUIRED',
    NOT_REQUIRED = 'NOT_REQUIRED'
}

export const AUTH_COOKIE_NAME: string = 'isAuth';

export const AUTH_CB_UCID: string = 'cb_ucid';

export enum AuthCookieValue {
    SET = '1',
    UNSET = '0'
}

export const MY_ACCOUNT: string = 'My Account';

export enum XmcStatus {
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED'
}

export enum DeviceType {
    TABLET = 'TABLET',
    PHONE = 'PHONE',
    SMART_WATCH = 'SMART_WATCH'
}

export enum UserState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    NON_PAY_SUSPENDED = 'NON_PAY_SUSPENDED',
    NOT_SUBSCRIBED = 'NOT_SUBSCRIBED',
    CLOSED = 'CLOSED',
    HOTLINED = 'HOTLINED'
}

export enum PlanEligibilityStatus {
    ELIGIBLE = 'ELIGIBLE',
    INELIGIBLE_IN_USE = 'INELIGIBLE_IN_USE',
    INELIGIBLE_OVERAGE = 'INELIGIBLE_OVERAGE',
    REQUIRES_UPGRADE = 'REQUIRES_UPGRADE',
    INELIGIBLE_BUCKET = 'INELIGIBLE_BUCKET'
}

export enum AccountEligibilityStatus {
    ELIGIBLE = 'ELIGIBLE',
    INELIGIBLE_HOTLINED = 'INELIGIBLE_HOTLINED',
    INELIGIBLE_NON_PAY_SUSPEND = 'INELIGIBLE_NON_PAY_SUSPEND'
}

export enum SalesChannel {
    ONLINE = 'ONLINE',
    RETAIL = 'RETAIL',
    TELESALES = 'TELESALES'
}

export enum ChargeType {
    DOWN_PAYMENT = 'DOWN_PAYMENT',
    LINE_SERVICE_PRIMARY = 'LINE_SERVICE_PRIMARY',
    DEVICE_FINANCED = 'DEVICE_FINANCED',
    DEVICE_PURCHASED = 'DEVICE_PURCHASED',
    INSURANCE_PLAN = 'INSURANCE_PLAN',
    NO_WIRELINE_SURCHARGE = 'NO_WIRELINE_SURCHARGE',
    DOMESTIC_CHARGE = 'DOMESTIC_CHARGE',
    INTERNATIONAL_CHARGE = 'INTERNATIONAL_CHARGE',
    ROAMING_CHARGE = 'ROAMING_CHARGE',
    ACCESSORY = 'ACCESSORY',
    HD_PASS = 'HD_PASS',
    MEX_CAN_PASS = 'MEX_CAN_PASS',
    DEVICE_SUBSIDY = 'DEVICE_SUBSIDY',
    DEVICE_ACCESS_FEE = 'DEVICE_ACCESS_FEE',
    EWASTE_FEE = 'EWASTE_FEE'
}

export enum ReturnStatus {
    SUBMITTED = 'SUBMITTED',
    RECEIVED = 'RECEIVED',
    GRADED = 'GRADED',
    REFUNDED = 'REFUNDED',
    CHARGED = 'CHARGED',
    CANCELLED = 'CANCELLED'
}

export enum ReturnFeeCategory {
    DAMAGE = 'DAMAGE',
    RESTOCKING = 'RESTOCKING'
}

export enum DataPlanType {
    UNLIMITED = 'UNLIMITED',
    BY_THE_GIG = 'BY_THE_GIG',
    BY_THE_GIG_SHARED = 'BY_THE_GIG_SHARED',
    SMART_WATCH = 'SMART_WATCH'
}

export enum OrderItemCategory {
    TABLET = 'TABLET',
    PHONE = 'PHONE',
    ACCESSORY = 'ACCESSORY',
    SMART_WATCH = 'SMART_WATCH'
}

export enum OrderItemType {
    PREORDER = 'PREORDER',
    BACKORDER = 'BACKORDER',
    REGULAR = 'REGULAR',
    RETURN = 'RETURN',
    EXCHANGE = 'EXCHANGE',
    WARRANTY_EXCHANGE = 'WARRANTY_EXCHANGE',
    UPGRADE = 'UPGRADE',
    UPGRADE_PREORDER = 'UPGRADE_PREORDER',
    UPGRADE_BACKORDER = 'UPGRADE_BACKORDER',
    NDEL = 'NDEL',
    NDEL_PREORDER = 'NDEL_PREORDER',
    NDEL_BACKORDER = 'NDEL_BACKORDER',
    SIMLED_BYOD = 'SIMLED_BYOD'
}

export enum PaymentCaptureStatus {
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL'
}

export enum TaxFeeCategory {
    TAX = 'TAX',
    COMCAST = 'COMCAST'
}

export enum FraudStatus {
    IN_REVIEW = 'IN_REVIEW',
    PASSED = 'PASSED',
    FAILED = 'FAILED'
}

export enum OrderStatus {
    SUBMITTED = 'SUBMITTED',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED'
}

export enum ReturnEligible {
    ELIGIBLE = 'ELIGIBLE',
    GRACE = 'GRACE',
    INELIGIBLE = 'INELIGIBLE'
}

export enum DeviceStatus {
    ACTIVE = 'ACTIVE',
    NDEL = 'NDEL',
    DPP_OUTSTANDING = 'DPP_OUTSTANDING',
    PENDING = 'PENDING',
    EXCHANGE = 'EXCHANGE',
    WARRANTY = 'WARRANTY',
    INSURANCE = 'INSURANCE',
    UPGRADE = 'UPGRADE'
}

export enum ActivationStatus {
    ACTIVE = 'ACTIVE',
    FAILED = 'FAILED',
    RFA = 'READY_FOR_ACTIVATION',
    NEW_IN_PROGRESS = 'NEW_LINE_IN_PROGRESS',
    PORT_IN_PROGRESS = 'PORT_IN_PROGRESS'
}

export enum ActivationType {
    NEW = 'NEW',
    PORT_IN = 'PORT_IN'
}

export enum Carriers {
    VERIZON = 'Verizon'
}

export enum LineStatus {
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',
    PENDING = 'PENDING',
    DEACTIVATED = 'DEACTIVATED',
    READY_FOR_ACTIVATION = 'READY_FOR_ACTIVATION'
}

export enum ProgressStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL'
}

export enum RetryPaymentCode {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    BILL_PAYMENT_FAILED = 'BILL_PAYMENT_FAILED',
    ORDER_PAYMENT_FAILED = 'ORDER_PAYMENT_FAILED',
    DEFAULT_INSTRUMENT_FAILED = 'DEFAULT_INSTRUMENT_FAILED',
    DELETE_TOKEN_FAILED = 'DELETE_TOKEN_FAILED',
    UPDATE_PAYMENT_INSTRUMENT_FAILED = 'UPDATE_PAYMENT_INSTRUMENT_FAILED',
    UPDATE_EXPIRYDATE_FAILED = 'UPDATE_EXPIRYDATE_FAILED'
}

export const REQUEST_INTERVAL: number = 5000;

export const RETRY_REQUEST_COUNT: number = 5;

export enum UserErrorType {
    NOT_USER = 'non-user',
    SECONDARY_USER = 'secondary-user',
    XMB_COMING_SOON = 'xmb-coming-soon'
}

export enum LineStatusDisplay {
    SUSPENDED = 'Suspended',
    PENDING = 'Not activated',
    DEACTIVATED = 'Deactivated'
}

export enum ModalsTypeById {
    CARRIER_LOCKED = 'CARRIER_LOCKED',
    UPDATE_OS = 'UPDATE_OS',
    FIVEG_PLANS = 'FIVEG_PLANS',
    ADDONS_UPSELL = 'ADDONS_UPSELL',
    CARRIER_LOCKED_STATUS = 'CARRIER_LOCKED_STATUS',
    TRADE_INS = 'TRADE_INS'
}

export enum FlowTypes {
    NEW_DEVICE = 'New Device',
    BYOD = 'BYOD-E2E Purchase Flow',
    SIM_LED = 'SIM-Led Purchase Flow'
}

export enum PurchaseTypes {
    SIM_LED = 'SIM-led',
    BYOD = 'BYOD',
    NEW_DEVICE = 'New device'
}

export enum SelectedLineOptionTypes {
    NEW_LINE = 'new-line',
    EXISTING_LINE = 'existing-line'
}

export enum EccEligibilityValues {
    BOTH = 'BOTH',
    PERSONAL_LIABLE = 'PL',
    BUSINESS_LIABLE = 'BL',
    NONE = 'NONE'
}

export enum CPCMessageCodes {
    INVALID_ENTRY = 'CPC-2001',
    ENTER_FIRST_NAME = 'CPC-2002',
    ENTER_LAST_NAME = 'CPC-2003',
    ENTER_VALID_CARD_NUMBER = 'CPC-2004',
    MAX_DIGITS = 'CPC-2005',
    ENTER_NUMBERIC_CHAR = 'CPC-2006',
    VALID_EXPIRATION_DATE = 'CPC-2007',
    CODE_MUST_BE_ATLEAST_3_DIGITS = 'CPC-2008',
    CANNOT_EXCEED_4_DIGITS = 'CPC-2009',
    CODE_MUST_BE_3_DIGITS = 'CPC-2010',
    CANNOT_EXCEED_3_DIGITS = 'CPC-2011',
    ENTER_VALID_SECURITY_CODE = 'CPC-2012',
    ENTER_A_VALID_ADDRESS = 'CPC-2013',
    ENTER_ADDRESS_LINE_1 = 'CPC-2014',
    ENTER_VALID_CITY = 'CPC-2015',
    ENTER_CITY = 'CPC-2016',
    ENTER_VALID_STATE = 'CPC-2017',
    SELECT_STATE = 'CPC-2018',
    ENTER_VALID_ZIP_CODE = 'CPC-2019',
    ENTER_ZIP_CODE = 'CPC-2020',
    SELECT_PROVINCE = 'CPC-2021',
    SELECT_ACCOUNT_TYPE = 'CPC-2022',
    ENTER_VALID_ROUTING_NUMBER = 'CPC-2023',
    CHECK_ROUTING_NUMBER = 'CPC-2024',
    CANNOT_EXCEED_9_DIGITS = 'CPC-2025',
    ENTER_VALID_ACCOUNT_NUMBER = 'CPC-2026',
    CANNOT_EXCEED_17_DIGITS = 'CPC-2027',
    TECHNICAL_ISSUES = 'CPC-3001',
    PAYMENT_DECLINED = 'CPC-3002',
    MISSING_OR_INVALID_ENTRIES = 'CPC-3003',
    UNABLE_TO_PROCESS_TRANSACTION = 'CPC-3004',
    UNABLE_TO_PROCESS_PAYMENT_TECHNICAL_ISSUES = 'CPC-3005',
    UNABLE_TO_PROCESS_PAYMENT_CONTACT_ASSISTANCE = 'CPC-3006',
    UNABLE_TO_PROCESS_REQUEST = 'CPC-3007',
    UNABLE_TO_PROCESS_PAYMENT_PLEASE_LOGIN = 'CPC-3008',
    UPDATE_AUTOMATIC_PAYMENT_METHOD = 'CPC-3009',
    INVALID_DATE_FOR_SCHEDULING_PAYMENT = 'CPC-3010',
    UNABLE_TO_PROCESS_REFUND = 'CPC-3011',
    PAYMENT_ALREADY_PROCESSED = 'CPC-3012',
    UNABLE_TO_PROCESS_PAYMENT_TRY_ANOTHER_METHOD = 'CPC-3013',
    CANNOT_ACCEPT_BANK_PAYMENTS = 'CPC-3014',
    CANNOT_ACCEPT_CARD_PAYMENTS = 'CPC-3015',
    UNABLE_TO_PROCESS_REQUEST_VISIT_STORE = 'CPC-3016',
    SCHEDULED_PAYMENTS = 'CPC-3017',
    UNABLE_TO_PROCESS_CHOOSE_ANOTHER_METHOD = 'CPC-3018',
    LIMITED_SCHEDULED_PAYMENTS = 'CPC-3019',
    REFUNDS_INITIATED = 'CPC-3020',
    PAYMENT_REVERSAL_NOT_COMPLETED = 'CPC-3021',
    UNABLE_TO_SCHEDULE_TRANSACTION = 'CPC-3022',
    UNABLE_TO_CONFIRM_PAYMENT = 'CPC-3023',
    CARD_REVERSAL_SUCCESSFUL = 'CPC-3024',
    TRY_AGAIN_LATER = 'CPC-3101',
    INVALID_CARD_NUMBER = 'CPC-3102',
    PROBLEM_PROCESSING_CARD = 'CPC-3103',
    PROBLEM_PROCESSING_CARD_TRY_DIFFERENT_ONE = 'CPC-3104',
    INVALID_SECURITY_CODE = 'CPC-3105',
    PROBLEM_WITH_CARD_NUMBER = 'CPC-3106',
    CARD_EXPIRED = 'CPC-3107',
    MISSING_REQUIRED_FIELDS = 'CPC-3108',
    INVALID_ADDRESS = 'CPC-3111',
    INVALID_DATA = 'CPC-3112'
}

export enum CpcCase {
    CPC_CONFIG_READY = 'CPC_CONFIG_READY',
    CPC_CONFIG_SUBMIT = 'CPC_CONFIG_SUBMIT',
    CPC_FORM_SUBMIT_RESPONSE = 'CPC_FORM_SUBMIT_RESPONSE',
    CPC_FORM_SUBMIT = 'CPC_FORM_SUBMIT',
    CPC_ERROR = 'CPC_ERROR'
}

export enum CpcDetails {
    JUMP_WEB_COMPONENT = 'jump-web-component',
    IFRAME = 'iframe',
    COMCAST = 'Comcast',
    MESSAGE_DISPLAY_LOCATION = 'top',
    AUTH_METHOD = 'oauth2_cima',
    IDENTITY_USER_TYPE = 'PRIMARY',
    ACCOUNT_CUSTOMER_CLASS= 'BUSINESS_MOBILE'
}

export enum ByodEligibilityMode {
    TRIAL = 'TRIAL',
    PRODUCTION = 'PRODUCTION'
}

export enum PlansEligibilityIntent {
    ADD = 'add',
    EDIT = 'edit'
}

export enum ContentPath {
    EXTENDED_PROMO_PATH = '/shop/extendedPromos',
    UNL_PRICING_PATH = '/config/modal/unlTable',
    XMC_PROMO_PATH = '/shop/xmcPromos',
    BYOD_BANNER_PATH = '/shop/footerBanner/byod',
    SIGN_IN_MODAL_PATH = '/shop/mobile/signin'
}

export enum LiabilityType {
    BUSINESS_LIABLE = 'BUSINESS_LIABLE',
    PERSONAL_LIABLE = 'PERSONAL_LIABLE'
}

export enum SELECTED_OFFER {
    ILC = 'ILC',
    BLECC = 'BL',
    PLECC = 'PL'
}

export enum FINANCE_APPROVAL_TYPE {
    FULLY_APPROVED = 'FULLY_APPROVED',
    PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
    NO_CREDIT = 'NO_CREDIT'
}

export enum WatchOs {
    APPLE = 'Apple',
    ANDROID = 'Android'
}

export enum DEFAULT_USER_DETAIL {
    EMAIL = 'OCT@CABLE.COMCAST.COM',
    DOMAIN = 'yopmail.com'
}

export enum COMMERCIAL_ROLES {
    BUSINESS_OWNER = 'BUSINESSOWNER'
}

export enum BYOD_DEVICE_TYPE {
    PHONE = 'PHONE',
    TABLET = 'TABLET',
    WATCH = 'WATCH'
}

export enum CIMA_CHECK {
    PASSED = 'PASSED',
    FAILED = 'FAILED'
}

export const MaskFields: string[] = ['FIRSTNAME', 'LASTNAME', 'EMAIL', 'PHONE', 'PRIMARYPHONE', 'ACCOUNTNUMBER', 'NICKNAME', 'DATEOFBIRTH', 'SSN', 'KEY', 'SMS', 'PHONENUMBER', 'CONTACTEMAIL', 'CONTACTNUMBER', 'LINENUMBER'];

export const FilteredApiToMask: string[] = ['/products', '/businesswebapi/visitorsession', '/product/details'];

export const PaymentInstrumentToMask: string = '/account/paymentinstrument';

