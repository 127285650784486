import { Injectable } from '@angular/core';
import { LogFields, LogLevel, LogProperties, LogType } from './model/LogFields';
import { Logger } from './logger';
import { ModelBase } from 'store/model-base';
import { StoreAction } from 'store/actions'; 
import { removePropertiesWithNullValues } from './LogHelper';
import { MaskPipe } from 'shared/pipes/mask';

@Injectable({
    providedIn: 'root'
})

export class LogService { 
  
    public static logger: Logger; 
    public static maskPipe: MaskPipe = new MaskPipe();    

    public static logMessage(properties: LogProperties): void {

        const logFields: LogFields = {
            Level: properties.logLevel,
            OperationType: properties.operationType,
            LogType: LogType.MESSAGE,
            ApiUrl: properties.apiUrl,
            Request: properties.request,
            Response: properties.response,
            Status: 'Success',
            BaseUrl: properties.baseUrl,
            Severity: properties.severity,
            StatusCode: properties.statusCode,
            Message: properties.message,
            Properties: removePropertiesWithNullValues(properties.properties),
            StartTimeUtc: properties.logTimeFields?.StartTimeUtc.toISOString(),
            EndTimeUtc: properties.logTimeFields?.EndTimeUtc.toISOString()
        };   
        Logger.log(logFields);
    }   

    public static logError(properties: LogProperties): void {
        
        const logFields: LogFields = {
            Level: LogLevel.error, 
            OperationType: properties.operationType,
            ApiUrl: properties.apiUrl,
            Request: properties.request ? this.maskPipe.maskJSONValue(properties.request, true) : undefined,
            LogType: LogType.SA,
            Response: properties.response,
            Status: 'Failure',
            BaseUrl: properties.baseUrl,
            Severity: properties.severity,
            StatusCode: properties.statusCode,
            Properties: properties.properties
        };
    
        Logger.log(logFields);
    }

    constructor(maskPipe: MaskPipe) {
        Object.assign(this, { maskPipe });
    }   
}

ModelBase.fetchMapping[StoreAction.LOG_INFO] = LogService.logMessage;
ModelBase.fetchMapping[StoreAction.LOG_ERROR] = LogService.logError;